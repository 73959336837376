import React, { useCallback, useState } from "react";

import axios from "axios";
import { AiFillGithub } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import useRegisterModal from "../../hooks/useRegisterModal";
import useLoginModal from "../../hooks/useLoginModal";
import Modal from "./Modal";
import Heading from "../Heading";
import Input from "../inputs/Input";
import { toast } from "react-hot-toast";
import Button from "../Button";
import MaskInput from "../inputs/MaskedInput";
import { UserService } from "../../services/UserService";

const validateEmail = (value: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(value) || "Insira um e-mail válido";
};

const validateCPF = (cpf: string): boolean | string => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return "CPF invalido";

  let sum = 0,
    rest;
  for (let i = 1; i <= 9; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10))) return "CPF invalido";

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(10, 11))) return "CPF invalido";

  return true;
};

const validatePassword = (senha: string): boolean | string => {
  if (senha.length < 8) {
    return "Senha deve ter mais de 8 caracteres";
  }
  return true;
};

const RegisterModal = () => {
  const registerModal = useRegisterModal();
  const loginModal = useLoginModal();
  const [isLoading, setIsLoading] = useState(false);
  const userService = new UserService();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FieldValues>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      cpf: "",
      userName: "",
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setIsLoading(true);
    data.userName = data.userName?.toLowerCase();
    data.email = data.email?.toLowerCase();

    userService
      .registerAccount(data)
      .then(() => {
        toast.success("Registrado com sucesso.");
        reset();
        registerModal.onClose();
      })
      .catch((error) => {
        toast.error("Erro ao criar usuario: " + error.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggle = useCallback(() => {
    loginModal.onOpen();
    registerModal.onClose();
  }, [loginModal, registerModal]);

  const bodyContent = (
    <div className="flex flex-col relative gap-4">
      <Heading
        title="Bem vindo ao Gigset"
        subtitle="Cadastre-se gratuitamente!                    
                "
        subsubtitle="Você estará agora criando seu perfil pessoal (não do projeto)"
        center
      />
      <Input
        id="email"
        label="Email (Pessoal)"
        disabled={isLoading}
        register={register}
        errors={errors}
        required
        validate={validateEmail}
      />
      <Input
        id="name"
        label="Nome completo (Pessoal)"
        disabled={isLoading}
        register={register}
        errors={errors}
        required
      />
      <Input
        id="userName"
        label="Nome de usuario"
        disabled={isLoading}
        register={register}
        errors={errors}
        required
      />
      <Input
        id="cpf"
        label="CPF"
        disabled={isLoading}
        register={register}
        errors={errors}
        mask="999.999.999-99"
        validate={validateCPF}
        required
      />
      <Input
        id="password"
        label="Password"
        type="password"
        disabled={isLoading}
        register={register}
        errors={errors}
        required
        validate={validatePassword}
        needEvaluation
      />
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4">
      {/* <Button
                outline
                label="Continue com Google"
                icon={FcGoogle}
                onClick={()=>{}}
            /> */}
      <div
        className="
                    text-center
                    font-light
                "
      >
        <div className="justify-center flex flex-row items-center gap-2">
          <div>Já tem um conta?</div>
          <div
            onClick={toggle}
            className="
                            text-lilas
                            cursor-pointer
                            hover:underline
                        "
          >
            Log in
          </div>
        </div>
      </div>
    </div>
  );

  const footerParagraph = (
    <>
      <p className="text-12px">
        Ao clicar em Cadastre-se, você concorda com nossos Termos,Política de
        Privacidade e Política de Cookies. Você poderá receber notificações por
        SMS e cancelar isso quando quiser.
      </p>
    </>
  );

  return (
    <Modal
      disabled={isLoading}
      isOpen={registerModal.isOpen}
      title="Cadastrar"
      actionLabel="Continuar"
      onClose={registerModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      body={bodyContent}
      footer={footerContent}
      register
      paragraphFooter={footerParagraph}
      centralizeParagraph
    />
  );
};

export default RegisterModal;
