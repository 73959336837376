import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import SideBar from "../sidebar/SideBar";
import Footer from "../footer/Footer";
import { useAuth } from "../../hooks/useAuth";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const auth = useAuth();
  const user = auth.user;
  const showSideBar =
    (location.pathname.startsWith("/user") ||
      location.pathname.startsWith("/admin")) &&
    user;

  return (
    <>
      {showSideBar ? (
        <>
          <SideBar>{children}</SideBar>
        </>
      ) : (
        <>
          <Navbar>
            {children}
            <Footer />
          </Navbar>
        </>
      )}
    </>
  );
};

export default Layout;
