import React from "react";
import NextButton from "../NextButton";
import PrevButton from "../PrevButton";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  handleNextPage: () => void;
  handlePrevPage: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  handleNextPage,
  handlePrevPage,
}) => {
  const getPaginationNumbers = () => {
    const pages: (number | string)[] = [];
    const delta = 1;
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }

    if (currentPage > 2 + delta) {
      pages.push(1, "...");
    } else {
      if (currentPage > delta) pages.push(1);
    }

    for (
      let i = Math.max(1, currentPage - delta);
      i <= Math.min(totalPages, currentPage + delta);
      i++
    ) {
      if (!pages.includes(i)) {
        pages.push(i);
      }
    }

    if (currentPage < totalPages - (1 + delta)) {
      pages.push("...", totalPages);
    } else {
      if (currentPage < totalPages - delta) {
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const pages = getPaginationNumbers();

  return (
    <div className="flex justify-end items-center gap-3 mt-4">
      <PrevButton disabled={currentPage === 1} onClick={handlePrevPage} />

      {pages.map((page, index) =>
        typeof page === "number" ? (
          <button
            key={index}
            onClick={() => onPageChange(page - 1)}
            className={`p-1  ${
              page === currentPage ? " text-lilas" : "hover:text-lilasHover"
            }`}
          >
            {page}
          </button>
        ) : (
          <span key={index} className="px-2 text-gray-500">
            {" "}
            {page}{" "}
          </span>
        )
      )}

      <NextButton
        disabled={currentPage === totalPages}
        onClick={handleNextPage}
      />
    </div>
  );
};

export default Pagination;
