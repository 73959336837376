import { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { IoMdClose } from "react-icons/io";

import usePortifolioModal from "../../hooks/usePortifolioModal";

import Modal from "./Modal";
import Heading from "../Heading";
import Input from "../inputs/Input";
import { toast } from "react-hot-toast";
import { ProjectService } from "../../services/ProjectService";
import { useNavigate } from "react-router-dom";
import SimpleInput from "../inputs/SimpleInput";
import { useAuth } from "../../hooks/useAuth";

const PortifolioModal = () => {
  const portifolioModal = usePortifolioModal();
  const [isLoading, setIsLoading] = useState(false);
  const projectService = new ProjectService();
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth.user;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      identifier: "",
      phone: user ? user.person.phone : "",
    },
  });

  const setCustomValue = (id: string, value: any) => {
    setValue(id, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    setCustomValue("logisticDetail.phone", user?.person?.phone);
  }, []);

  const identifier = watch("identifier");
  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setIsLoading(true);
    data.identifier = data.identifier.toLowerCase().replace(/\s+/g, "");
    projectService
      .createProject(data)
      .then((response) => {
        navigate(`user/myprojects/${data.identifier}`, {
          state: data.identifier,
        });
        toast.success("Portifolio criado!");
        portifolioModal.onClose();
      })
      .catch((e) => {
        toast.error(e.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const bodyContent = (
    <div className="flex flex-col gap-8">
      <Heading
        title="Como voce descreveria seu projeto?"
        subtitle="Informações basicas"
      />

      <Input
        id="name"
        label="Nome do projeto"
        disabled={isLoading}
        register={register}
        errors={errors}
        required
      />
      <hr />

      <Controller
        name="identifier"
        control={control}
        rules={{
          required: "Este campo é obrigatório",
          pattern: {
            value: /^[a-zA-Z0-9_-]+$/, // Permite apenas letras, números, "_" e "-"
            message: "Apenas dígitos, letras, '-' e '_' são permitidos",
          },
        }}
        render={({ field }) => (
          <SimpleInput
            label={"Username do projeto*"}
            id="identifier"
            value={identifier}
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(
                /[^a-zA-Z0-9_-]/g,
                ""
              );
              field.onChange(sanitizedValue);
            }}
            placeholder=""
          />
        )}
      />
      <label>gigset.com.br/{identifier}</label>
      <hr />
      <Input
        id="logisticDetail.phone"
        label="Telefone DDD + número"
        disabled={isLoading}
        register={register}
        errors={errors}
        required
        mask="(99) 99999-9999"
      />
    </div>
  );

  return (
    <Modal
      isOpen={portifolioModal.isOpen}
      onClose={portifolioModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionLabel="Criar"
      title="Crie seu projeto"
      body={bodyContent}
    />
  );
};

export default PortifolioModal;
