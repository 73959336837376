import axiosInstance from "./axios";

const route = "/person";
//TODO types
export class PersonService {
  async getAllPerson() {
    return await axiosInstance.get(route);
  }
  async getPersonById(id: any) {
    return await axiosInstance.get(route + `${id}`);
  }
  async getPersonByEmail(email: string) {
    return await axiosInstance.get(route + `/email/${email}`);
  }

  async createPerson(data: any) {
    return await axiosInstance.post(route, data);
  }

  async deletePerson(id: any) {
    return await axiosInstance.delete(route + `/${id}`);
  }

  async updatePerson(data: any, id: string) {
    try {
      return await axiosInstance.put(route + `/${id}`, data);
    } catch (error: any) {
      throw error.response.data;
    }
  }
}
