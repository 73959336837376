import PaginatedResponse from "../models/PaginatedResponse";
import Project from "../models/Project";
import ProjectCardDto from "../models/ProjectCardDTO";
import axiosInstance from "./axios";

const route = "/project";
//TODO types
export class ProjectService {
  async getAllProjects() {
    return await axiosInstance.get(route);
  }

  async getAllProjectsCards(
    page: number,
    query?: string
  ): Promise<PaginatedResponse<ProjectCardDto>> {
    try {
      let completeRoute = route + `/getAllProjecs?page=${page}&size=30`;

      if (query) completeRoute = completeRoute + `&${query}`;

      const response = await axiosInstance.get(completeRoute);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectByIdentifier(identifier: string): Promise<Project> {
    try {
      const response = await axiosInstance.get<Project>(
        `${route}/identifier${identifier}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectByIdentifierForEditing(identifier: string): Promise<Project> {
    try {
      const response = await axiosInstance.get<Project>(
        `${route}/identifier/editing${identifier}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  async getMyProjects(): Promise<ProjectCardDto[]> {
    try {
      const response = await axiosInstance.get(route + "/getMyProjects");
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  async addProjectContact(indentifier: string) {
    return await axiosInstance.put(route + "/addContact/" + indentifier);
  }

  async createProject(data: any) {
    try {
      return await axiosInstance.post(route, data);
    } catch (error: any) {
      throw error.response.data;
    }
  }

  async deleteProject(id: any) {
    return await axiosInstance.delete(route + `/${id}`);
  }

  async updateProject(data: any, id: string) {
    return await axiosInstance.put(route + `/${id}`, data);
  }
}
