import React, { useRef, useState } from "react";
import useCropModal from "../../hooks/useCropModal";
import Button from "../Button";
import CropModal from "../modals/CropModal";

import { FileService } from "../../services/FileService";
import toast from "react-hot-toast";

type ImageImputProps = {
  type: "profile" | "cover" | "gallery";
  onImageUpload: (images: FormData, imageType: string) => void;
  pathAndName: string;
  galleryImages?: string[];
};

const ImageImput: React.FC<ImageImputProps> = ({
  type,
  pathAndName,
  galleryImages,
  onImageUpload,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imgSrc, setImgSrc] = useState([""]);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    const quantity = files.length + (galleryImages?.length || 0);
    if (quantity > 4) {
      toast.error(
        "Você pode salvar até 4 fotos no plano gratuito. Se precisar de mais, entre em contato!"
      );
      return;
    }

    const imageUrls: string[] = [];
    let filesProcessed = 0;

    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        imageUrls.push(reader.result?.toString() || "");
        filesProcessed++;

        if (filesProcessed === files.length) {
          setImgSrc(imageUrls);
          setIsModalOpen(true);
        }
      });
      reader.readAsDataURL(file);
    });
  };

  const onCropComplete = (croppedImage: File[]) => {
    const data = new FormData();

    croppedImage.forEach((image) => {
      data.append("files", image, image.name);
    });

    onImageUpload(data, "media.coverImage");
    setIsModalOpen(false);
  };

  const getAspectRatio = () => {
    switch (type) {
      case "gallery":
        return 1280 / 720;
      case "cover":
        return 1900 / 620;
      default:
        return 1;
    }
  };

  const getMinWidth = () => {
    switch (type) {
      case "gallery":
        return 1280;
      case "cover":
        return 1900;
      default:
        return 250;
    }
  };

  return (
    <div className="max-w-[300px]">
      <Button label="Adicionar imagem" onClick={handleClick} />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        multiple={type === "gallery"}
        onChange={handleFileChange}
      />

      <CropModal
        galleryImages={galleryImages}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        imgSrc={imgSrc}
        apectRatio={getAspectRatio()}
        minWidth={getMinWidth()}
        circularCrop={type === "profile"}
        onCropComplete={onCropComplete}
        pathAndName={pathAndName}
        isMulti={type === "gallery"}
      />
    </div>
  );
};

export default ImageImput;
