import React, { useState } from "react";
import { motion } from "framer-motion";

const states = [
  { label: "", value: "active", color: "bg-lilas" },
  { label: "", value: "null", color: "bg-customModal" },
  { label: "", value: "inactive", color: "bg-redError" },
];

type TTripleToggle = {
  onChange: (newState: string) => void;
  startIndex: number;
};

const TripleToggle: React.FC<TTripleToggle> = ({ onChange, startIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  const handleToggle = () => {
    const newIndex = (currentIndex + 1) % states.length;
    setCurrentIndex(newIndex);
    onChange(states[newIndex].value);
  };

  return (
    <div
      className="relative flex items-center bg-customText p-1 rounded-full w-20 cursor-pointer"
      onClick={handleToggle}
    >
      {/* Fundo animado que se move */}
      <motion.div
        className={`absolute top-0  bottom-0 w-1/3 rounded-full ${states[currentIndex].color}`}
        animate={{ left: `${currentIndex * 33.3}%` }} // Move dinamicamente
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
      />

      {/* Opções de toggle */}
      {states.map((state, index) => (
        <div
          key={state.value}
          className="w-1/3 text-center py-1 font-semibold relative z-10"
        >
          {state.label}
        </div>
      ))}
    </div>
  );
};

export default TripleToggle;
