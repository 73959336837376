import React from "react";

interface AvatarProps {
  src: string | null | undefined;
  big?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ src, big }) => {
  return (
    <img
      alt="Avatar"
      className={`rounded-full
          ${big && "border-[8px] border-lilasHover"}
          `}
      style={{
        height: 214,
        width: 214,
        objectFit: "cover",
        marginTop: "15px",
        marginRight: "15px",
      }}
      src={`${src}?t=${Date.now()}` || "/images/avatar.png"}
    />
  );
};

export default Avatar;
