import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import TMonthlyStats from "../../models/MonthlyStats";

type TBarChartComponent = {
  data: TMonthlyStats[];
  title: string;
};

const BarChartComponent: React.FC<TBarChartComponent> = ({ data, title }) => {
  return (
    <div className="bg-customBackground p-4 rounded-lg shadow-md ">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total" fill="#4F46E5" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
