import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

type TData = {
  name: string;
  value: number;
};

type TPieCharComponent = {
  data: TData[];
  colors: string[];
};

const PieChartComponent: React.FC<TPieCharComponent> = ({ data, colors }) => {
  const handleClick = (entry: TData) => {};

  return (
    <div className="bg-customBackground p-4 rounded-lg shadow-md ">
      <h3 className="text-lg font-semibold mb-4">Projetos</h3>
      <ResponsiveContainer width="100%" height={350}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={90}
            dataKey="value"
            label
            onClick={(_, index) => handleClick(data[index])}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartComponent;
