import React, { useEffect, useMemo, useState } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { CiCirclePlus } from "react-icons/ci";
import { RiCloseCircleFill } from "react-icons/ri";
import AvatarUploader from "./Uploads/AvatarUploader";
import SimpleInput from "./SimpleInput";
import ImageImput from "./ImageImput";
import { FileService } from "../../services/FileService";
import toast from "react-hot-toast";

const path = process.env.REACT_APP_MINIO_URL;

type InputData = {
  nameLabel: string;
  subNameLabel: string;
  photoLabel: string;
};

type InputItemCard = {
  nameId: string;
  subNameId: string;
  photoId: string;
  nameLabel: string;
  subNameLabel: string;
  photoLabel: string;
  nameValue: string;
  subNameValue: string;
  photoValue: string[];
  index: number;
  control: Control<FieldValues, any>;
  imagePathName: string;
  onChange: (field: any, value: string) => void;
  active: boolean;
  onRemove: () => void;
  onActivate: () => void;
  title: string;
};

const InputItemCard: React.FC<InputItemCard> = ({
  nameId,
  subNameId,
  photoId,
  nameLabel,
  subNameLabel,
  photoLabel,
  nameValue,
  subNameValue,
  photoValue,
  onChange,
  onRemove,
  onActivate,
  active = false,
  index,
  imagePathName,
  control,
  title,
}) => {
  const [profileImage, setProfileImage] = useState<string[]>(photoValue);

  const fileService = useMemo(() => new FileService(), []);

  useEffect(() => {
    setProfileImage(photoValue);
  }, [photoValue]);

  return (
    <div className="relative flex flex-col gap-5 border-customBackground aspect-[350/360] border-[1px] rounded-[6px] p-2 w-full">
      {active ? (
        <>
          <button
            className="absolute top-5 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
            onClick={onRemove}
          >
            <RiCloseCircleFill size={12} />
          </button>
          <label className="text-xs">Dados sobre o {title}</label>
          <div className="flex flex-col gap-5 ">
            <section className="w-full  h-[250px]">
              <Controller
                name={photoId}
                control={control}
                render={({ field }) => (
                  <div>
                    <div
                      className={`max-w-[300px] mb-4 flex flex-col gap-2 ${
                        profileImage[0] && "hidden"
                      }`}
                    >
                      <ImageImput
                        type="profile"
                        pathAndName={`${imagePathName}/photos/imagem-${
                          nameValue && subNameValue
                            ? nameValue + subNameValue
                            : nameValue
                        }`}
                        onImageUpload={(imagesData: FormData) => {
                          //Esse imagesData é o retorno quando chamamos essa função no componente filho
                          //Dessa Forma a gente consegue a imagem cortada e envia para o backend sem precisar de chamar uma outra função
                          fileService
                            .uploadFile(imagesData)
                            .then((response) => {
                              const newImagePath = response.data;
                              setProfileImage([newImagePath]);
                              field.onChange(newImagePath); //Funciona pq o Controller sabe onde ediatar que no caso é dado pelo photoId.
                              onChange("photo", newImagePath);
                              toast.success("Imagem enviada com sucesso!");
                            })
                            .catch(() => {
                              toast.error(
                                "Erro ao salvar imagem, tente novamente"
                              );
                            });
                        }}
                      />
                    </div>
                    <div
                      className={`h-full w-full pb-20 md:pb-0  gap-4 relative justify-center items-center flex flex-row ${
                        !profileImage[0] && profileImage[0] !== "" && "hidden"
                      }`}
                    >
                      <div
                        key={index}
                        style={{
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        <AvatarUploader imgSrc={path + profileImage[0]} />

                        <button
                          className="absolute top-2 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
                          onClick={() => {
                            field.onChange(null);
                            onChange("photo", "");
                            setProfileImage([]);
                          }}
                        >
                          <RiCloseCircleFill size={28} />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              />
            </section>
            <Controller
              name={nameId}
              control={control}
              render={({ field }) => (
                <SimpleInput
                  placeholder={"insira o " + nameLabel}
                  id={nameId}
                  onChange={(e) => {
                    field.onChange(e);
                    onChange("name", e.target.value);
                  }}
                  value={nameValue}
                  label={nameLabel}
                />
              )}
            />
            <Controller
              name={subNameId}
              control={control}
              render={({ field }) => (
                <SimpleInput
                  placeholder={"insira a " + subNameLabel}
                  id={subNameId}
                  onChange={(e) => {
                    field.onChange(e);
                    onChange("subName", e.target.value);
                  }}
                  value={subNameValue}
                  label={subNameLabel}
                />
              )}
            />
          </div>
        </>
      ) : (
        <>
          <section
            onClick={onActivate}
            className="flex flex-col justify-center items-center text-lilasHover h-full"
          >
            <CiCirclePlus size={250} />
          </section>
        </>
      )}
    </div>
  );
};

export default InputItemCard;
