import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

interface ImageSliderProps {
  images: string[];
}

const path = process.env.REACT_APP_MINIO_URL;

const CardSlider: React.FC<ImageSliderProps> = ({ images }) => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "1px",
          left: "-15px",
          right: 0,
          display: "flex",
          justifyContent: "center",
        }}
      ></div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "6px",
          height: "6px",
          borderRadius: "50%",
          background: "white",
          opacity: 0.7,
          margin: "0 3px",
        }}
      ></div>
    ),
  };
  images =
    images && images[0] !== undefined
      ? images.map((image) => path + image)
      : ["/images/galeryplaceholder.jpg"];

  return (
    <div className="relative w-full mx-auto">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            className="flex justify-center h-full min-h-[500px]  items-center rounded-t-[20px] "
          >
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full min-h-[235px] md:min-h-[200px] h-full object-fit"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardSlider;
