import React from "react";

interface IAvatarUploader {
  imgSrc: string | null | undefined;
  big?: boolean;
}

const AvatarUploader: React.FC<IAvatarUploader> = ({ imgSrc, big }) => {
  return (
    <img
      alt="Avatar"
      className={`rounded-full
            border-[8px]
            border-lilasHover
            object-cover
            `}
      style={{
        height: 220,
        width: 220,
        objectFit: "cover",
        marginTop: "15px",
        marginRight: "15px",
      }}
      src={`${imgSrc}?t=${Date.now()}` || "/images/avatar.png"}
    />
  );
};

export default AvatarUploader;
