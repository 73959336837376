import React, { useEffect, useState } from "react";
import AuthenticatedContainer from "../../components/containers/AuthenticatedContainer";
import BasicAuthenticatedContainer from "../../components/containers/BasicAuthenticatedContainer";
import PortifolioEditSection from "../../components/portifolio/PortifolioEditSetion";
import Input from "../../components/inputs/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import ImageImput from "../../components/inputs/ImageImput";
import { FileService } from "../../services/FileService";
import toast from "react-hot-toast";
import { PersonService } from "../../services/PersonService";
import { RiCloseCircleFill } from "react-icons/ri";
import AvatarUploader from "../../components/inputs/Uploads/AvatarUploader";
import User from "../../models/User";
import { UserService } from "../../services/UserService";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const fileService = new FileService();
const personService = new PersonService();
const userService = new UserService();
const PATH = process.env.REACT_APP_MINIO_URL;

const ProfileEditing = () => {
  const { pathname } = useLocation();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
  } = useForm<FieldValues>();

  const [user, setUser] = useState<User>();
  const userName = pathname.split("/").pop();

  const [profileImage, setProfileImage] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userName)
      userService
        .getUserByUserName(userName)
        .then((response) => {
          setUser(response);
          handleInitializeEditing(response);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.error);
          navigate("/user/myprojects");
        });
  }, [userName]);

  const setCustomValue = (id: string, value: any) => {
    setValue(id, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const onSubmitPerson: SubmitHandler<FieldValues> = (data) => {
    if (!user?.person) return;
    setIsLoading(true);
    personService
      .updatePerson(data, user.person.id)
      .then((res) => {
        toast.success("Pessoa editada!");
      })
      .catch((error) => {
        toast.error(error.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInitializeEditing = (user: User) => {
    setCustomValue("firstName", user.person?.firstName);
    setCustomValue("email", user.person?.email);
    setCustomValue("country", user.person?.country);
    setCustomValue("city", user.person?.city);
    setCustomValue("state", user.person?.state);
    setCustomValue("phone", user.person?.phone);
    setCustomValue("birthDate", user.person?.birthDate);
    setCustomValue("userName", user.userName);
    setProfileImage(user.person?.profilePic ? user.person.profilePic : []);
  };

  const updateImagePerson: SubmitHandler<FieldValues> = (data) => {
    if (!user?.person) return;

    personService
      .updatePerson(data, user.person.id)
      .then(() => {})
      .catch(() => {});
  };

  const handleRemoveImage = (imageType: string) => {
    setProfileImage([""]);
    setCustomValue(imageType, [""]);

    handleSubmit(updateImagePerson)().then(() => {
      toast.success("Imagem removida!");
    });
  };

  const onImageUpload = (imagesData: FormData, imageType: string) => {
    if (!user?.person) return;
    setIsLoading(true);
    fileService
      .uploadFile(imagesData)
      .then((response) => {
        setCustomValue(imageType, response.data);
        handleSubmit(updateImagePerson)().then(() => {
          toast.success("Imagem enviada com sucesso!");
          setProfileImage(response.data);
        });
      })
      .catch(() => {
        toast.error("Erro ao salvar imagem tente novamente");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthenticatedContainer title="Perfil">
      <BasicAuthenticatedContainer>
        <PortifolioEditSection title="Dados Pessoais">
          <div
            id="basicInformation"
            className="
                flex
                flex-col
                md:flex-row
                w-full
                gap-12
              "
          >
            {/* PERFIL PIC */}
            <div
              className="
                w-full  
                md:w-1/2
                "
            >
              Imagem do perfil
              <div className=" flex flex-col gap-2">
                <div
                  className={
                    profileImage[0] && profileImage[0] !== "" ? "hidden" : ""
                  }
                >
                  <ImageImput
                    type="profile"
                    pathAndName={`${userName}/profile/imagem`}
                    onImageUpload={(imagesData: FormData) => {
                      onImageUpload(imagesData, "profilePic");
                    }}
                  />
                </div>
                <div
                  className={`h-full w-full pb-10 md:pb-0 justify-center items-center flex flex-row'   gap-4 relative`}
                >
                  <div
                    style={{ position: "relative" }}
                    className={
                      profileImage[0] && profileImage[0] !== "" ? "" : "hidden"
                    }
                  >
                    <AvatarUploader imgSrc={PATH + profileImage[0]} />

                    <button
                      className="absolute top-2 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
                      onClick={() => handleRemoveImage("profilePic")}
                    >
                      <RiCloseCircleFill size={28} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Dados Pessoais */}
            <div
              className="
                  flex
                  flex-col
                  w-full
                  md:w-1/2
                  gap-2
                "
            >
              <Input
                register={register}
                errors={errors}
                label="Nome"
                id="firstName"
                Placeholder="Digite o seu nome"
              />
              <Input
                register={register}
                errors={errors}
                label="E-mail"
                id="email"
                required
                Placeholder="Digite o seu e-mail"
              />
              <label className="text-lg">Localização*</label>
              <div
                className="
                    flex
                    flex-col
                    md:flex-row
                    w-full
                    justify-between
                    gap-4
                  "
              >
                <Input
                  id="country"
                  label="País"
                  register={register}
                  errors={errors}
                  Placeholder="País"
                  onChange={() => trigger("localization.state")}
                />
                <Input
                  id="state"
                  label="Estado"
                  register={register}
                  errors={errors}
                  Placeholder="Estado"
                  onChange={() => trigger("localization.state")}
                />
                <Input
                  id="city"
                  register={register}
                  errors={errors}
                  label="Cidade"
                  Placeholder="Cidade"
                  onChange={() => trigger("localization.city")}
                />
              </div>
              <div
                className="
                  flex
                  flex-col
                  md:flex-row
                  w-full
                  justify-between
                  gap-4
                "
              >
                <Input
                  id="phone"
                  label="Telefone DDD + número"
                  disabled={isLoading}
                  register={register}
                  errors={errors}
                  Placeholder="Telefone"
                  mask="(99) 99999-9999"
                />
              </div>

              <Button
                label="Atualizar"
                small
                onClick={handleSubmit(onSubmitPerson)}
                disabled={isLoading}
              />
            </div>
          </div>
        </PortifolioEditSection>
        {/* <PortifolioEditSection title="Dados de usuario">
          <div
            id="userInformation"
            className="
                flex
                flex-col
                md:flex-row
                w-full
                gap-12
              "
          >
            <Input
              id="userName"
              label="Nome de usuario"
              register={register}
              errors={errors}
              Placeholder="Nome de usuario"
              onChange={() => trigger("localization.state")}
            />
            <Button label="Alterar Senha" onClick={() => {}} />
          </div>
        </PortifolioEditSection> */}
      </BasicAuthenticatedContainer>
    </AuthenticatedContainer>
  );
};

export default ProfileEditing;
