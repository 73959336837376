import React, { useEffect, useState } from "react";
import AuthenticatedContainer from "../../components/containers/AuthenticatedContainer";
import { AdminService } from "../../services/AdminService";
import DashBoardDto from "../../models/DashBoardDto";
import StatCard from "../../components/containers/StatCard";
import PieChartComponent from "../../components/containers/PieChartComponent";
import BarChartComponent from "../../components/containers/BarChartComponent";
import ProspectChart from "../../components/containers/ProspectChart";
import { LineChart } from "recharts";
import LineChartComponent from "../../components/containers/LineChartComponent";
import TMonthlyStats from "../../models/MonthlyStats";
import BasicAuthenticatedContainer from "../../components/containers/BasicAuthenticatedContainer";

const adminService = new AdminService();

const monthNames: { [key: string]: string } = {
  "01": "Jan",
  "02": "Fev",
  "03": "Mar",
  "04": "Abr",
  "05": "Mai",
  "06": "Jun",
  "07": "Jul",
  "08": "Ago",
  "09": "Set",
  "10": "Out",
  "11": "Nov",
  "12": "Dez",
};

const formatMonth = (yearMonth: string) => {
  const [year, month] = yearMonth.split("-");
  return `${monthNames[month]} ${year}`;
};

const AdminDashBoard = () => {
  const [dashBoardData, setDashBoardData] = useState<DashBoardDto>();
  const [projectPerMonth, setProjectPerMonth] = useState<TMonthlyStats[]>([]);
  const [usersPerMonth, setUsersPerMonth] = useState<TMonthlyStats[]>([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [dashboard, projects, users] = await Promise.all([
          adminService.getDashBoardData(),
          adminService.getProjectsPerMonth(),
          adminService.getUsersPerMonth(),
        ]);

        setDashBoardData(dashboard);
        setProjectPerMonth(
          projects.map((item) => ({
            month: formatMonth(item.month),
            total: item.total,
          }))
        );
        setUsersPerMonth(
          users.map((item) => ({
            month: formatMonth(item.month),
            total: item.total,
          }))
        );
      } catch (error) {
        console.error("Erro ao buscar dados do dashboard:", error);
      }
    };

    fetchDashboardData();
  }, []);

  if (!dashBoardData || !projectPerMonth || !usersPerMonth)
    return (
      <AuthenticatedContainer title="DashBoard">
        <div className="flex flex-row gap-4"></div>
      </AuthenticatedContainer>
    );

  const pieChartProjectData = [
    { name: "Ativos", value: dashBoardData.activeProjects },
    { name: "Inativos", value: dashBoardData.inactiveProjects },
  ];
  const pieChartProjectColor = ["#6638FE", "#FC4349"];

  return (
    <AuthenticatedContainer title="DashBoard">
      <BasicAuthenticatedContainer>
        <div className="flex flex-col gap-5">
          <div className="p-3 flex flex-row gap-2  justify-between max-w-[330px] sm:max-w-[600px]  md:max-w-full overflow-scroll">
            <StatCard
              title="Número de usuarios"
              value={dashBoardData.totalUsers}
              href="/admin/users"
            />

            <StatCard
              title="Número de projetos"
              value={
                dashBoardData.activeProjects + dashBoardData.inactiveProjects
              }
              href="/admin/projects"
            />
            <StatCard
              title="Número de projetos Inativos"
              value={dashBoardData.inactiveProjects}
            />
            <StatCard
              title="Usuarios criados no último mês"
              value={usersPerMonth[usersPerMonth.length - 1].total}
            />
            <StatCard
              title="Projetos criados no ultio mês"
              value={projectPerMonth[projectPerMonth.length - 1].total}
            />
          </div>
          <div className="grid md:grid-cols-2  grid-cols-1 gap-2 w-full">
            <BarChartComponent
              data={projectPerMonth}
              title={"projetos Criados"}
            />
            <PieChartComponent
              data={pieChartProjectData}
              colors={pieChartProjectColor}
            />
            <BarChartComponent
              data={usersPerMonth}
              title={"Usuarios Criados"}
            />
          </div>
        </div>
      </BasicAuthenticatedContainer>
    </AuthenticatedContainer>
  );
};

export default AdminDashBoard;
