import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

interface AdminProps {
  children: React.ReactNode;
}

const AdminRoute: React.FC<AdminProps> = ({ children }) => {
  const auth = useAuth();
  const user = auth.user;
  const navigate = useNavigate();
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (!auth.isAuthenticated || !user?.admin) {
      navigate("/", { replace: true, state: { from: location } });
    } else {
      setIsVerified(true);
    }
  }, [auth.isAuthenticated, location, navigate]);

  if (!isVerified) {
    return null;
  }

  return <>{children}</>;
};

export default AdminRoute;
