import React from "react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";

interface IItemList {
  icon: IconType;
  label: string;
  path: string;
}

const ItemList: React.FC<IItemList> = ({ icon, label, path, icon: Icon }) => {
  return (
    <li className="text-md hover:text-lilasHover">
      <Link className="flex flex-row items-center gap-4" to={path}>
        {Icon && <Icon />}
        <div>{label}</div>
      </Link>
    </li>
  );
};

export default ItemList;
