import React from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

type TNextButton = {
  disabled: boolean;
  onClick: () => void;
  size?: number;
};

const NextButton: React.FC<TNextButton> = ({
  disabled,
  onClick,
  size = 16,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="
         p-1
         bg-lilas
         hover:bg-lilasHover
         rounded-full
         text-customModal
         disabled:bg-customBackground
       "
    >
      <GoChevronRight size={size} />
    </button>
  );
};

export default NextButton;
