import React from "react";
import { useAuth } from "../../hooks/useAuth";
import useRegisterModal from "../../hooks/useRegisterModal";
import { MdOutlineLogout } from "react-icons/md";
import { FaBell } from "react-icons/fa";
import MiniAvatar from "./MiniAvatar";

const PATH = process.env.REACT_APP_MINIO_URL;

const Register = () => {
  const registerModal = useRegisterModal();
  const { user, logout } = useAuth();
  const handleRegister = () => {
    registerModal.onOpen();
  };

  return (
    <>
      <div
        onClick={user ? () => {} : handleRegister}
        className="
                    md:py-1
                    md:px-2
                    flex
                    flex-row
                    items-center
                    gap-3
                    rounded-full
                    cursor-pointer
                    transition
                "
      >
        {user ? (
          <>
            <div className="pr-2 border-r-[1px] border-customText gap-3 flex flex-row">
              <div>
                <FaBell />
              </div>
              <div onClick={logout} className="cursor-pointer">
                <MdOutlineLogout />
              </div>
            </div>
            <div className="hidden xl:flex flex-row items-center gap-2">
              {user.userName}
              <MiniAvatar
                src={
                  user?.person.profilePic && user?.person.profilePic[0] !== ""
                    ? PATH + user.person.profilePic[0]
                    : undefined
                }
              />
            </div>
          </>
        ) : (
          <>
            <div
              translate="no"
              className="pr-2 border-r-[1px] border-customText hidden xl:block"
            >
              Crie sua conta
            </div>
            <div className="block">
              <MiniAvatar src={""} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Register;
