import React from "react";

interface ICoverUploader {
  imgSrc: string;
}

const CoverUploader: React.FC<ICoverUploader> = ({ imgSrc }) => {
  return (
    <img
      alt="Cover"
      src={`${imgSrc}?t=${Date.now()}`}
      className="w-[250px] h-[200px]  object-contain  xs:w-[300px] xs:h-[250px] sm:w-full sm:h-auto sm:max-h-[620px] sm:min-h-[620px]"
    />
  );
};

export default CoverUploader;
