import DashBoardDto from "../models/DashBoardDto";
import TMonthlyStats from "../models/MonthlyStats";
import PaginatedResponse from "../models/PaginatedResponse";
import TProjectAdminDto from "../models/ProjectAdminDto";
import TUserAdminDto from "../models/UserAdminDto";
import axiosInstance from "./axios";

const route = "/admin";

export class AdminService {
  async getDashBoardData(): Promise<DashBoardDto> {
    try {
      const response = await axiosInstance.get(route + "/dashboard");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectsPerMonth(): Promise<TMonthlyStats[]> {
    try {
      const response = await axiosInstance.get(route + "/projectsPerMonth");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProjectsToTable(
    page: number,
    size: number,
    query?: string
  ): Promise<PaginatedResponse<TProjectAdminDto>> {
    try {
      let completeRoute = route + `/projectsToTable?page=${page}&size=${size}`;

      if (query) completeRoute = completeRoute + `&${query}`;

      const response = await axiosInstance.get(completeRoute);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUsersToTable(
    page: number,
    size: number,
    query?: string
  ): Promise<PaginatedResponse<TUserAdminDto>> {
    try {
      let completeRoute = route + `/usersToTable?page=${page}&size=${size}`;

      if (query) completeRoute = completeRoute + `&${query}`;

      const response = await axiosInstance.get(completeRoute);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUsersPerMonth(): Promise<TMonthlyStats[]> {
    try {
      const response = await axiosInstance.get(route + "/UsersPerMonth");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateProjectOwner(id: string, userName: string): Promise<string> {
    try {
      const response = await axiosInstance.put(
        route + `/updateProjectOwner/${id}/${userName}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
}
