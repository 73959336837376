import React, { useEffect, useState } from "react";
import AuthenticatedContainer from "../../components/containers/AuthenticatedContainer";
import { AdminService } from "../../services/AdminService";
import TProjectAdminDto from "../../models/ProjectAdminDto";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import DynamicTable from "../../components/Table/DynamicTable";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../components/modals/ConfimarionDialogModal";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { ProjectService } from "../../services/ProjectService";
import toast from "react-hot-toast";
import { error } from "console";
import Modal from "../../components/modals/Modal";
import SimpleInput from "../../components/inputs/SimpleInput";
import Heading from "../../components/Heading";
import Search from "../../components/navbar/Search";
import TripleToggle from "../../components/TripleToggle";

const formatDate = (dateString: string) => {
  return format(new Date(dateString), "dd/MM/yyyy", { locale: ptBR });
};

const formatPhone = (phone: string) => phone.replace(/\D/g, "");

const adminService = new AdminService();
const projectService = new ProjectService();

const ProjectTable = () => {
  const [projectsDto, setProjectsDto] = useState<any>();
  const [page, setPage] = useState(0);
  const [quantity, setQuantity] = useState(16);
  const [totalPages, setTotalPages] = useState(0);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [projectName, setProjectName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newOwner, setNewOwner] = useState("");
  const [projectId, setProjectId] = useState("");

  const [activeFilter, setActiveFilter] = useState<true | false | null>(null);

  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const activeQuery = params.get("active");

    if (activeQuery === "true") {
      setActiveFilter(true);
    } else if (activeQuery === "false") {
      setActiveFilter(false);
    } else {
      setActiveFilter(null); // Caso o parâmetro 'active' não exista
    }
  }, [location.search]);

  useEffect(() => {
    const query = activeFilter !== null ? `active=${activeFilter}` : "";
    adminService.getProjectsToTable(page, quantity, query).then((resp) => {
      const formattedData = resp.content.map((item) => ({
        ID: item.id,
        Nome: item.name,
        Identificador: item.identifier,
        Dono: "/user/MyPerfil/" + item.ownerName,
        Telefone: formatPhone(item.phone),
        Chamado: item.timesContacted,
        Plano: item.plan,
        Ativo: item.active ? "Sim" : "Não",
        Criado: formatDate(item.createAt),
        Atualizado: formatDate(item.updateAt),
      }));
      setTotalPages(resp.totalPages);
      setProjectsDto(formattedData);
    });
  }, [refresh, page, quantity, activeFilter]);

  useEffect(() => {
    setPage(0);
  }, [activeFilter]);

  const handleNextPage = () => {
    if (page < totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (page > 0) setPage((prev) => prev - 1);
  };

  const handleSetPage = (page: number) => {
    setPage(page);
  };

  const handleEditButton = (item: Record<string, any>) => {
    window.location.href = "/user/myprojects/" + item.Identificador;
  };

  const handleDeleteButton = (item: Record<string, any>) => {
    setConfirmHandler(() => handleConfirm(item.ID));
    setProjectName(item.Nome);
    onOpen();
  };

  const { onOpen, setConfirmHandler, setRefuseHandler } = useConfirmationModal(
    (state) => ({
      onOpen: state.onOpen,
      setConfirmHandler: state.setConfirmHandler,
      setRefuseHandler: state.setRefuseHandler,
    })
  );

  const handleConfirm = (id: string) => {
    handleDelete(id);
  };

  const handleDelete = (id: string) => {
    if (id) {
      projectService
        .deleteProject(id)
        .then((response) => {
          setRefresh(!refresh);
        })
        .catch((error) => {
          toast.error(error.error);
        });
    }
  };

  const handleTransferOwnership = (item: Record<string, any>) => {
    setProjectId(item.ID);
    setIsModalOpen(true);
  };

  const handleModalSubmit = () => {
    adminService
      .updateProjectOwner(projectId, newOwner)
      .then((resp) => {
        toast.success(resp);
        setRefresh(!refresh);
        setIsModalOpen(false);
        setProjectId("");
        setNewOwner("");
      })
      .catch((error) => {
        toast.error(error.error);
      });
  };

  const handleToggleChange = (newState: string) => {
    setActiveFilter(
      newState === "active" ? true : newState === "inactive" ? false : null
    );
  };

  const menu = (
    <div className="flex flex-row gap-4">
      <TripleToggle
        onChange={handleToggleChange}
        startIndex={activeFilter == null ? 1 : activeFilter == false ? 2 : 0}
      />
      {activeFilter == null && <p className="text-xs ">Tudo</p>}
      {activeFilter == false && (
        <p className="text-xs text-redError">Inativos</p>
      )}
      {activeFilter == true && <p className="text-xs text-lilas">ativos</p>}
    </div>
  );

  const modalBody = (
    <div>
      <Heading title="Trocar Dono" />
      <SimpleInput
        label="Usuario ou e-mail do novo dono"
        id="newOwner"
        placeholder="Usuario"
        onChange={(e) => {
          setNewOwner(e.target.value);
        }}
        value={newOwner}
      />
    </div>
  );

  return (
    <AuthenticatedContainer title="Projetos" menu={menu}>
      <DynamicTable
        data={projectsDto}
        isAdmin
        urlFields={["Dono"]}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handleSetPage={handleSetPage}
        page={page}
        totalPages={totalPages}
        onEdit={handleEditButton}
        onDelete={handleDeleteButton}
        onExtraAction={handleTransferOwnership}
      />
      <ConfirmationDialog
        message={`Tem certeza que deseja excluir o projeto ${projectName}`}
      />
      <Modal
        title="Trocar dono"
        isOpen={isModalOpen}
        actionLabel="Aceitar"
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
        body={modalBody}
      />
    </AuthenticatedContainer>
  );
};

export default ProjectTable;
