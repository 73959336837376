import React, { useEffect, useState } from "react";
import AuthenticatedContainer from "../../components/containers/AuthenticatedContainer";
import DynamicTable from "../../components/Table/DynamicTable";
import { AdminService } from "../../services/AdminService";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { UserService } from "../../services/UserService";
import toast from "react-hot-toast";
import ConfirmationDialog from "../../components/modals/ConfimarionDialogModal";

const adminService = new AdminService();
const userService = new UserService();

const formatDate = (dateString: string) => {
  return format(new Date(dateString), "dd/MM/yyyy", { locale: ptBR });
};

const formatPhone = (phone: string) => phone.replace(/\D/g, "");

const UsersTable = () => {
  const [usersDto, setUsersDto] = useState<any>();
  const [page, setPage] = useState(0);
  const [quantity, setQuantity] = useState(16);
  const [totalPages, setTotalPages] = useState(0);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [userName, setUserName] = useState("");

  useEffect(() => {
    adminService.getUsersToTable(page, quantity).then((resp) => {
      const formattedData = resp.content.map((item) => ({
        ID: item.id,
        Nome: item.name,
        Usuario: item.userName,
        "E-mail": item.email || " ",
        Telefone: formatPhone(item.phone || ""),
        Papel: item.role,
        Criado: formatDate(item.createAt),
        Atualizado: formatDate(item.updateAt),
      }));
      setTotalPages(resp.totalPages);
      setUsersDto(formattedData);
    });
  }, [refresh, page, quantity]);

  const handleNextPage = () => {
    if (page < totalPages) setPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (page > 0) setPage((prev) => prev - 1);
  };

  const handleSetPage = (page: number) => {
    setPage(page);
  };

  const handleEditButton = (item: Record<string, any>) => {
    window.location.href = "/user/myperfil/" + item.Usuario;
  };

  const handleDeleteButton = (item: Record<string, any>) => {
    setConfirmHandler(() => handleConfirm(item.ID));
    setUserName(item.Usuario);
    onOpen();
  };

  const { onOpen, setConfirmHandler, setRefuseHandler } = useConfirmationModal(
    (state) => ({
      onOpen: state.onOpen,
      setConfirmHandler: state.setConfirmHandler,
      setRefuseHandler: state.setRefuseHandler,
    })
  );

  const handleConfirm = (id: string) => {
    handleDelete(id);
  };

  const handleDelete = (id: string) => {
    if (id) {
      userService
        .deleteUser(id)
        .then((response) => {
          setRefresh(!refresh);
        })
        .catch((error) => {
          toast.error(error.error);
        });
    }
  };

  const menu = <div>Buscar</div>;

  return (
    <AuthenticatedContainer title="Usuarios">
      <DynamicTable
        data={usersDto}
        isAdmin
        urlFields={["Dono"]}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handleSetPage={handleSetPage}
        page={page}
        totalPages={totalPages}
        onEdit={handleEditButton}
        onDelete={handleDeleteButton}
      />
      <ConfirmationDialog
        message={`Tem certeza que deseja excluir o projeto ${userName}`}
      />
    </AuthenticatedContainer>
  );
};

export default UsersTable;
