import React from "react";
import { FaRegTrashAlt, FaPencilAlt } from "react-icons/fa";
import { PiUserSwitchFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";

interface DynamicTableProps {
  data: Record<string, any>[];
  page: number;
  totalPages: number;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  handleSetPage: (page: number) => void;
  isAdmin?: boolean;
  urlFields?: string[];
  onEdit?: (item: Record<string, any>) => void;
  onDelete?: (item: Record<string, any>) => void;
  onExtraAction?: (item: Record<string, any>) => void;
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  data,
  page,
  totalPages,
  handleNextPage,
  handlePrevPage,
  handleSetPage,
  isAdmin = false,
  urlFields = [],
  onEdit,
  onDelete,
  onExtraAction,
}) => {
  if (!data || data.length === 0) {
    return <p className="text-gray-500 text-center">Nenhum dado disponível</p>;
  }

  const hiddenColumns = ["ID"];
  const headers = Object.keys(data[0]).filter(
    (header) => !hiddenColumns.includes(header)
  );

  const getLastPartOfUrl = (url: string) => {
    const parts = url.split("/");
    return parts[parts.length - 1]; // Retorna a última parte da URL
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full shadow-lg  rounded-lg ">
        {/* Cabeçalho */}
        <thead>
          <tr className="bg-lilas border-b border-customBorder">
            {headers.map((header, index) => (
              <th
                key={header}
                className={`py-1 px-4 text-center border-l border-customCards ${
                  index === 0 ? "rounded-tl-lg border-0" : ""
                } 
                ${index === headers.length - 1 && isAdmin ? "border-r" : ""}
                ${
                  index === headers.length - 1 && !isAdmin
                    ? "rounded-tr-lg"
                    : ""
                }
                 `}
              >
                {header.toUpperCase()}
              </th>
            ))}
            {isAdmin && (
              <th className="py-1 px-4 text-center rounded-tr-lg">AÇÕES</th>
            )}
          </tr>
        </thead>

        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="hover:bg-lilasTable transition odd:bg-customCards even:bg-customBackground border-b border-customBorder"
            >
              {headers.map((field) => (
                <td key={field} className="py-2 px-4 text-center">
                  {urlFields.includes(field) ? (
                    <Link
                      to={row[field]}
                      className="text-blue-500 hover:underline"
                    >
                      {getLastPartOfUrl(row[field])}{" "}
                    </Link>
                  ) : typeof row[field] === "object" ? (
                    JSON.stringify(row[field])
                  ) : (
                    row[field]
                  )}
                </td>
              ))}

              {isAdmin && (
                <td className="py-2 px-4 text-center flex justify-center gap-2">
                  <button
                    onClick={() => onEdit?.(row)}
                    className="bg-verde hover:bg-verdeHover text-white p-1 rounded-md flex items-center gap-1"
                  >
                    <FaPencilAlt size={16} />
                  </button>

                  <button
                    onClick={() => onDelete?.(row)}
                    className="bg-redError hover:bg-customRed text-white p-1 rounded-md flex items-center gap-1"
                  >
                    <FaRegTrashAlt size={16} />
                  </button>

                  {/* Botão extra para projetos */}
                  {onExtraAction && (
                    <button
                      onClick={() => onExtraAction(row)}
                      className="bg-green-500 hover:bg-green-600 text-white p-1 rounded-md flex items-center gap-1"
                    >
                      <PiUserSwitchFill size={16} />
                    </button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={page + 1}
        totalPages={totalPages}
        onPageChange={handleSetPage}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
      />
    </div>
  );
};

export default DynamicTable;
