import React, { useEffect, useRef, useState } from "react";
import useCropModal from "../../hooks/useCropModal";
import Modal from "./Modal";
import Heading from "../Heading";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type Crop,
} from "react-image-crop";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import NextButton from "../NextButton";
import PrevButton from "../PrevButton";

type TCropModal = {
  isModalOpen: boolean;
  imgSrc: string[];
  minWidth: number;
  circularCrop?: boolean;
  apectRatio: number;
  pathAndName: string;
  isMulti?: boolean;
  galleryImages?: string[];
  onCropComplete: (croppedImage: File[]) => void;
  setIsModalOpen: (value: boolean) => void;
};

const CropModal: React.FC<TCropModal> = ({
  isModalOpen,
  imgSrc,
  circularCrop,
  apectRatio,
  minWidth,
  pathAndName,
  isMulti,
  galleryImages,
  onCropComplete,
  setIsModalOpen,
}) => {
  const [crop, setCrop] = useState<Crop>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [croppedImages, setCroppedImages] = useState<File[]>([]);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [galleryUrl, setGalleryUrl] = useState(galleryImages || [""]);

  const onImageLoad = (e: React.ChangeEvent<HTMLImageElement>) => {
    const { height, width } = e.target;
    imgRef.current = e.currentTarget;

    const crop = makeAspectCrop(
      {
        unit: "px",
        width: minWidth,
      },
      apectRatio,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  useEffect(() => {
    if (galleryImages !== undefined) setGalleryUrl(galleryImages);
  }, [galleryImages]);

  const handleSave = async () => {
    const croppedImage = await getCroppedImage();
    if (croppedImage) {
      setCroppedImages((prev) => {
        const updatedCroppedImages = [...prev, croppedImage];
        if (currentIndex == imgSrc.length - 1)
          onCropComplete(updatedCroppedImages);
        return updatedCroppedImages;
      });

      if (currentIndex < imgSrc.length - 1) {
        setCurrentIndex((prev) => prev + 1);
        setCrop(undefined);
      } else {
        setIsModalOpen(false);
        setCroppedImages([]);
        setCurrentIndex(0);
        if (isMulti) {
          setGalleryUrl([""]);
        }
      }
    }
  };

  const handleNextImage = async () => {
    if (currentIndex < imgSrc.length - 1) {
      const croppedImage = await getCroppedImage();
      if (croppedImage) {
        setCroppedImages((prev) => [...prev, croppedImage]);
        setCurrentIndex((prev) => prev + 1);
        setCrop(undefined);
      }
    }
  };

  const handlePreviousImage = () => {
    if (currentIndex > 0) {
      setCroppedImages((prev) => prev.slice(0, -1));
      setCurrentIndex((prev) => prev - 1);
      setCrop(undefined);
    }
  };

  const getCroppedImage = async () => {
    if (!imgRef.current || !crop) return;
    const canvas = document.createElement("canvas");

    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const maxWidth = 1920;
    const maxHeight = 1080;
    let newWidth = crop.width * scaleX;
    let newHeight = crop.height * scaleY;

    if (newWidth > maxWidth) {
      newHeight = (newHeight * maxWidth) / newWidth;
      newWidth = maxWidth;
    }
    if (newHeight > maxHeight) {
      newWidth = (newWidth * maxHeight) / newHeight;
      newHeight = maxHeight;
    }

    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      newWidth,
      newHeight
    );

    if (isMulti) {
      let number = 0;
      galleryUrl?.forEach((path) => {
        const words = path.split("/");
        const lastWord = words[words.length - 1];
        if (lastWord.includes("" + number)) {
          number++;
        }
      });
      setGalleryUrl([...galleryUrl, `imagem-${number}.jpg`]);
      return new Promise<File>((resolve) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], pathAndName + "-" + number + ".jpg", {
                  type: "image/jpeg",
                })
              );
            }
          },
          "image/jpeg",
          0.8
        );
      });
    }

    return new Promise<File>((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(
              new File([blob], pathAndName + ".jpg", {
                type: "image/jpeg",
              })
            );
          }
        },
        "image/jpeg",
        0.8
      );
    });
  };

  const bodyContent = (
    <>
      <Heading title="Cortar imagem" center />
      <ReactCrop
        crop={crop}
        onChange={(c) => setCrop(c)}
        circularCrop={circularCrop}
        keepSelection
        aspect={apectRatio}
        minWidth={minWidth}
      >
        {!isMulti ? (
          <img src={imgSrc[0]} onLoad={onImageLoad} />
        ) : (
          <div className="flex flex-row items-center">
            <div
              className="
              absolute
              left-[-20px]"
            >
              <PrevButton
                disabled={currentIndex === 0}
                onClick={handlePreviousImage}
                size={30}
              />
            </div>
            <img ref={imgRef} src={imgSrc[currentIndex]} onLoad={onImageLoad} />

            <div
              className="
              absolute
              right-[-20px]"
            >
              <NextButton
                disabled={currentIndex == imgSrc.length - 1}
                onClick={handleNextImage}
                size={30}
              />
            </div>
          </div>
        )}
      </ReactCrop>
    </>
  );

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      onSubmit={handleSave}
      actionLabel="Salvar"
      title="Cortar imagem"
      body={bodyContent}
    />
  );
};

export default CropModal;
