import { ReactNode, createContext, useEffect, useReducer, useRef } from "react";
import { validateToken } from "../utils/jwt";
import { resetSession, setSession } from "../utils/session";
import User from "../models/User";
import { useCookies } from "react-cookie";
import { UserService } from "../services/UserService";

interface State {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
}

interface Action {
  type: string;
  payload?: any;
}

interface ContextProps {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refresh: () => Promise<void>;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const AuthContext = createContext<ContextProps>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
});

const handlers: { [key: string]: (state: State, action: Action) => State } = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
};

const reducer = (state: State, action: Action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

interface AuthProviderProps {
  children: ReactNode;
}

let globalLogout: () => Promise<void>;
let globalRefresh: () => Promise<void>;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "accessToken",
    "refreshToken",
    "userName",
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useRef(false);
  const userService = new UserService();

  useEffect(() => {
    if (isMounted.current) return;
    const initialize = async () => {
      try {
        const { accessToken } = cookies;
        if (accessToken && validateToken(accessToken)) {
          setSession(accessToken);
          const response = await userService.getUserByLogin();
          const { data: user } = response;
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (error) {
        console.error(error);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();

    isMounted.current = true;
  }, [cookies]);

  const getTokens = async (email: string, password: string) => {
    const data = {
      username: email,
      password: password,
    };
    try {
      const response = await userService.login(data);
      setCookie("accessToken", response.data.authenticationToken);
      setCookie("refreshToken", response.data.refreshToken);
      setCookie("userName", response.data.userName);
      setSession(response.data.authenticationToken);
    } catch (error) {
      throw error;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      await getTokens(email, password);
      const response = await userService.getUserByLogin();
      const { data: user } = response;
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logout = async () => {
    try {
      const data = {
        refreshToken: cookies.refreshToken,
        userName: cookies.userName,
      };

      resetSession();

      await userService.logout(data).then(() => {
        removeCookie("accessToken", { path: "/" });
        removeCookie("refreshToken", { path: "/" });
        removeCookie("userName", { path: "/" });
        dispatch({ type: "LOGOUT" });
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const refresh = async () => {
    const data = {
      refreshToken: cookies.refreshToken,
      userName: cookies.userName,
    };
    resetSession();
    await userService.refreshLogin(data).then((response) => {
      setCookie("accessToken", response.data.authenticationToken);
      setSession(response.data.authenticationToken);
    });
  };

  globalLogout = logout;
  globalRefresh = refresh;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
export { globalLogout, globalRefresh };
