import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../navbar/Logo";
import ItemList from "./ItemList";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { FaTable } from "react-icons/fa";
import { AiOutlineProject } from "react-icons/ai";

import MiniAvatar from "../navbar/MiniAvatar";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import { useAuth } from "../../hooks/useAuth";

interface ISideBar {
  children: React.ReactNode;
}

const PATH = process.env.REACT_APP_MINIO_URL;

const SideBar: React.FC<ISideBar> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const user = auth.user;
  const isAdmin = user?.admin;

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-row w-full w-max-full overflow-x-hidden">
      <motion.div
        className={`fixed inset-y-0 left-0 w-64 bg-customModal h-screen z-50 md:relative flex flex-col items-center md:w-64 md:translate-x-0 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        initial={{ x: "-100%" }}
        animate={{
          x: isOpen || window.innerWidth >= 768 ? 0 : "-100%",
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        {/* <div className="w-64 flex flex-col h-screen bg-customModal  items-center "> */}
        <div className="flex flex-col py-8 gap-4 w-4/5">
          <div
            className="
              flex
              w-full
              justify-center
              pb-4
            "
          >
            <Logo />
          </div>
          <div
            className="
              flex
              flex-row
              items-center
              gap-2
              "
          >
            <MiniAvatar
              src={
                user?.person.profilePic && user?.person.profilePic[0] !== ""
                  ? PATH + user.person.profilePic[0]
                  : undefined
              }
            />
            Todos os projetos
          </div>
        </div>
        {/* <hr className="w-4/5" /> */}
        {/* <ul className="space-y-4 p-4 w-4/5"> */}
        {/* <ItemList icon="/sideBarIcons/home.png" label="Dashboard" path="user/dashboard"/> */}
        {/* </ul> */}
        {/* <hr className="w-4/5" /> */}
        {/* <ul className="space-y-4 p-4 w-4/5"> */}
        {/* <ItemList icon="/sideBarIcons/contato.png" label="Contatos" path="user/contact"/> */}
        {/* <ItemList icon="/sideBarIcons/prospecção.png" label="Prospecção" path="user/prospection"/> */}
        {/* <ItemList icon="/sideBarIcons/negociacoes.png" label="Negociações" path="user/negotiation"/> */}
        {/* <ItemList icon="/sideBarIcons/fechamento.png" label="Fechamentos" path="user/closings"/> */}
        {/* </ul> */}
        <hr className="w-4/5" />
        <ul className="space-y-4 p-4 w-4/5">
          {/* <ItemList icon="/sideBarIcons/logistica.png" label="Logísticas" path="user/logistic"/> */}
          {/* <ItemList icon="/sideBarIcons/financeiro.png" label="Financeiro" path="user/financial"/> */}
          <ItemList
            icon={IoPersonOutline}
            label="Editar Perfil"
            path={"user/myperfil/" + user?.userName}
          />
          <ItemList
            icon={AiOutlineProject}
            label="Gerenciar Projetos"
            path="user/myprojects"
          />
        </ul>
        {isAdmin && (
          <div className="flex flex-col w-4/5 text-verde">
            <hr />
            <p className="px-6 pt-2">Administradores</p>
            <ul className="space-y-4 p-4">
              <ItemList
                icon={MdOutlineDashboard}
                label="DashBoard"
                path="admin"
              />
              <ItemList icon={FaTable} label="Projetos" path="admin/projects" />
              <ItemList icon={FaTable} label="Usuarios" path="admin/users" />
            </ul>
          </div>
        )}

        {/* </div > */}
      </motion.div>

      {/* Tela principal */}
      <div className="flex-1">
        <button
          className="md:hidden fixed top-4 left-4 z-50"
          onClick={toggleSidebar}
        >
          {isOpen ? <IoCloseOutline size={24} /> : <IoMenuOutline size={24} />}
        </button>
        {children}
      </div>
    </div>
  );
};

export default SideBar;
