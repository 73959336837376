import React from "react";
import { useAuth } from "../../hooks/useAuth";
import Register from "../navbar/Register";
import { useNavigate } from "react-router-dom";
import { IoHome } from "react-icons/io5";

interface IAuthenticatedContainer {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  menu?: React.ReactNode;
  topMenu?: React.ReactNode;
}

const AuthenticatedContainer: React.FC<IAuthenticatedContainer> = ({
  title,
  subtitle,
  children,
  menu,
  topMenu,
}) => {
  const navigate = useNavigate();

  return (
    <div className="h-full flex  flex-col">
      <header
        className="
                    bg-lilas
                    p-5
                    flex 
                    flex-row
                    items-center
                    justify-between
                "
      >
        <div
          className="
                        pl-6
                        md:pl-0
                        flex
                        flex-row
                        items-center
                        gap-3
                        text-xs
                        md:text-base
                    "
        >
          <span
            className="
                            font-bold
                        "
          >
            {title}
          </span>
          {subtitle && (
            <span
              className="
                            border-l-[1px]
                            border-customText
                            pl-3
                        "
            >
              {subtitle}
            </span>
          )}
          <div>{topMenu}</div>
        </div>
        <div
          className="
                    flex
                    flex-row
                    items-center
                    gap-2
                "
        >
          <div onClick={() => navigate("/")} className="cursor-pointer">
            <IoHome />
          </div>
          <Register />
        </div>
      </header>
      {menu && (
        <div
          className="
            py-4
            pl-8
            border-b-2
            border-customModal
            bg-customBackground
        "
        >
          {menu}
        </div>
      )}
      <div
        className="
            bg-customCards
            flex
            h-full
            w-full
            max-w-full
            max-h-screen  
            overflow-x-auto
        "
      >
        <div
          className="
                    mx-8
                    mt-10
                    w-full
                    overflow-x-auto
                "
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedContainer;
