import axios from 'axios';
import { globalLogout, globalRefresh } from '../context/JWTAuthContext';
import toast from 'react-hot-toast';

const baseURL = process.env.REACT_APP_API_URL

const axiosInstance = axios.create({
    baseURL
})

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {

        if (error.response.status === 403) {

            toast.error("Você não tem permissão para acessar esse recurso.");
            // window.history.back()
        }
        if (error.response.data.message === "401 UNAUTHORIZED \"Token Expirou\"") {
            try {
                await globalRefresh();
                window.location.href = window.location.pathname
            } catch (refreshError) {
                console.error("Erro ao tentar refresh do token:", refreshError);
            }
        }
        return Promise.reject(error)

    }
)


export default axiosInstance;