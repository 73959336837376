import React from "react";
import { GoChevronLeft } from "react-icons/go";

type TPrevButton = {
  disabled: boolean;
  onClick: () => void;
  size?: number;
};

const PrevButton: React.FC<TPrevButton> = ({
  disabled,
  onClick,
  size = 16,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="
                    p-1
                    bg-lilas
                    hover:bg-lilasHover
                    rounded-full 
                    text-customModal
                    disabled:bg-customBackground
                  "
    >
      <GoChevronLeft size={size} />
    </button>
  );
};

export default PrevButton;
