import React, { useState } from "react";
import useRecoverPasswordModal from "../../hooks/useRecoverPasswordModal";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Heading from "../Heading";
import Input from "../inputs/Input";
import Modal from "./Modal";
import toast from "react-hot-toast";
import { UserService } from "../../services/UserService";

const RecoverPasswordModal = () => {
  const passwordRecoverModal = useRecoverPasswordModal();
  const [isLoading, setIsLoading] = useState(false);
  const userService = new UserService();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setIsLoading(true);
    userService
      .requirePasswordRecover(data.email)
      .then(() => {
        toast.success("E-mail enviado");
        passwordRecoverModal.onClose();
      })
      .catch((error) => {
        toast.error("Não foi possivel enviar o e-mail");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const bodyContent = (
    <div className="flex flex-col  space-y-2">
      <Heading title="Recuperar conta:" center />
      <Input
        id="email"
        label="Email"
        disabled={isLoading}
        register={register}
        errors={errors}
        required
      />
    </div>
  );

  return (
    <Modal
      disabled={isLoading}
      isOpen={passwordRecoverModal.isOpen}
      title="Login"
      actionLabel="Recuperar senha"
      onClose={passwordRecoverModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      body={bodyContent}
    />
  );
};

export default RecoverPasswordModal;
