import React from "react";
import { useNavigate } from "react-router-dom";
type TStatCard = {
  title: string;
  value: number;
  href?: string;
};

const StatCard: React.FC<TStatCard> = ({ title, value, href }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (!href) return;
    navigate(href);
  };
  return (
    <div
      onClick={handleNavigate}
      className={`p-2 h-auto w-auto min-w-[150px] flex flex-col justify-between bg-customBackground md:p-4 rounded-lg shadow-md text-center transition-transform duration-400  relative z-10 ${
        href &&
        "hover:cursor-pointer hover:-translate-y-1 hover:-translate-x-0.5 hover:shadow-lg"
      }`}
    >
      <h3 className="text-sm md:text-lg font-semibold">{title}</h3>
      <p className=" text-base md:text-2xl font-bold text-lilas">{value}</p>
    </div>
  );
};

export default StatCard;
