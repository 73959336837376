import React from "react";
interface IBasicAuthenticatedContainer {
  children: React.ReactNode;
}

const BasicAuthenticatedContainer: React.FC<IBasicAuthenticatedContainer> = ({
  children,
}) => {
  return (
    <div
      className="
        p-4
        h-[93%]
        max-h-[93%]
        w-full
        max-w-full  
        bg-customModal
        rounded-[6px]
        overflow-auto
        overflow-x-hidden
        pb-20
    "
    >
      {children}
    </div>
  );
};

export default BasicAuthenticatedContainer;
